import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

import Container from "../../components/Container/Container"
import Product from "../../components/Product/Product"
import ProductHeader from "../../components/Product/ProductHeader"
import ProductDescription from "../../components/Product/ProductDescription"
import ProductDocs from "../../components/Product/ProductDocs"
import ProductGuide from "../../components/Product/ProductGuide"

import ProductDocsList from "../../components/Product/ProductDocsList"
import ProductAccordionListItem from "../../components/Product/ProductAccordion/ProductAccordionListItem"

import cbZasadaDzialania from "../../images/products/cb/cb-zasada-dzialania.jpg"
import rzutCb from "../../images/products/cb/rzutCB.png"
import przeplywyStopnie from "../../images/products/cb/przeplywy-stopnie.png"

const WymiennikiCb = () => {
  const { cb } = useStaticQuery(
    graphql`
      query {
        cb: file(relativePath: { eq: "products/cb.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <Layout pageInfo={{ pageName: "wymienniki-cb" }}>
      <SEO title="Wymienniki lutowane miedzią - seria CB" />
      <Container>
        <Product>
          <ProductHeader headerImage={cb.childImageSharp.fluid}>
            Typoszereg płytowych lutowanych wymienników ciepła Alfa Laval CB
            charakteryzuje się efektywną wymianą ciepła i kompaktową budową.
            Wymienniki ciepła Alfa Laval z serii CB to najczęściej stosowane
            urządzenia do wymiany ciepła. Swą popularność zawdzięczają wysokiemu
            stosunkowi wydajności i jakości do ceny. Są doskonałym rozwiązaniem
            dla instalacji, gdzie przestrzeń instalacyjna jest ograniczona lub
            kosztowna.
          </ProductHeader>
          <ProductDocs>
            <ProductDocsList title="Karty katalogowe">
              <ProductAccordionListItem linkTo="/cb/cb-broszura.pdf">
                Płytowe lutowane wymienniki ciepła Alfa Laval - broszura
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CBH16.pdf">
                Alfa Laval CB16/CBH16
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CBH18.pdf">
                Alfa Laval CB18/CBH18
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CBH18DW.pdf">
                Alfa Laval CBH18DW
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CBXP27.pdf">
                Alfa Laval CBXP27
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CB30.pdf">
                Alfa Laval CB30/CBH30
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CB60.pdf">
                Alfa Laval CB60/CBH60
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CB62.pdf">
                Alfa Laval CB62/CBH62
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CB65.pdf">
                Alfa Laval CB65/CBH65
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CB110.pdf">
                Alfa Laval CB110/CBH110
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CB112.pdf">
                Alfa Laval CB112/CBH112/CBXP112
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CB200.pdf">
                Alfa Laval CB200/CBH200
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CB200AQ.pdf">
                Alfa Laval CB200AQ/CBH200AQ
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CB300.pdf">
                Alfa Laval CB300/CBH300
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CB400.pdf">
                Alfa Laval CB400
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/cb/CB410.pdf">
                Alfa Laval CB410
              </ProductAccordionListItem>
            </ProductDocsList>
            <ProductDocsList title="Instrukcje">
              <ProductAccordionListItem linkTo="/cb/cb-instrukcja-obslugi.pdf">
                Instrukcja obsługi
              </ProductAccordionListItem>
            </ProductDocsList>
            <ProductDocsList title="Deklaracje zgodności i atesty">
              <ProductAccordionListItem linkTo="/cb/cb-deklaracja-zgodnosci.pdf">
                Deklaracja zgodności
              </ProductAccordionListItem>
            </ProductDocsList>
          </ProductDocs>
          <ProductDescription>
            <h4 style={{ marginTop: "1rem" }}>
              Zasada działania płytowych lutowanych wymienników ciepła
            </h4>
            <div className="product__description-flex">
              <p>
                Powierzchnię wymiany ciepła wymiennika tworzy pakiet cienkich
                profilowanych płyt ze stali kwasoodpornej lutowanych miedzią.
                Uformowane między płytami kanały oraz otwory w narożnikach płyt
                umożliwiają przepływ cieczy wymieniających ciepło. Media
                przepływają przez kolejne, różne kanały, zwykle w
                przeciwprądzie, dla uzyskania jak najwyższej efektywności
                wymiany ciepła.
              </p>
              <div
                className="product__description-imagebox"
                style={{ maxWidth: 320 }}
              >
                <img src={cbZasadaDzialania} alt="" />
              </div>
            </div>
            <div className="product__description-flex">
              <div className="product__description-imagebox">
                <img src={rzutCb} alt="" />
              </div>
              <p style={{ alignSelf: "center" }}>
                Zastosowana konstrukcja pozwala na wykorzystanie całej
                powierzchni płyt wymiennika w procesie wymiany ciepła i
                utrzymanie jego niewielkich rozmiarów oraz wagi.
              </p>
            </div>
            <div className="product__description-flex">
              <p>
                Wymienniki lutowane z króćcami do średnicy 2” mają przyłącza
                gwintowane, dla większych średnic stosuje się przyłącza z
                końcówkami do spawania lub kołnierzowe. Produkowane są jako
                jednostki jedno-, dwu- i wielo-przepływowe. Istnieje możliwość
                wyboru standardowej konﬁguracji wymiennika lub zaprojektowanie
                dokładnie do określonych parametrów pracy.
              </p>
            </div>
            <div className="product__description-imagebox product__description-imagebox--hidden-on-mobile">
              <img src={przeplywyStopnie} alt="" />
            </div>
            <h4>Zalety płytowych lutowanych wymienników ciepła:</h4>
            <ul>
              <li>Kompaktowa budowa</li>
              <li>Łatwość montażu</li>
              <li>
                Wysoka niezawodność dzięki próbom ciśnieniowym i testom
                szczelności
              </li>
              <li>
                Minimalizacja zanieczyszczeń i efekt samooczyszczania dzięki
                turbulencji przepływu wynikającego z zastosowania specjalnych
                wytłoczeń
              </li>
              <li>Wysoka wydajność przy zbliżonych różnicach temperatur</li>
            </ul>
            <h4>Zastosowania płytowych lutowanych wymienników ciepła:</h4>
            <ul>
              <li>
                instalacje ciepłownicze - w węzłach cieplnych (wymienniki C.O. i
                C.W.U), kotłowniach oraz na źródłach zasilania;
              </li>
              <li>
                instalacje chłodnicze - przy źródłach zasilania, jako skraplacze
                oraz parowniki w urządzeniach;
              </li>
              <li>
                instalacje przemysłowe - procesy, chłodzenie urządzeń, odzysk
                ciepła;
              </li>
            </ul>
            <ProductGuide />
          </ProductDescription>
        </Product>
      </Container>
    </Layout>
  )
}

export default WymiennikiCb
